import { useCallback, useContext, useEffect } from "react";

import { SessionContext } from 'modules/commons/contexts';

const SESSION_KEY = "SESSION";

function useSessions() {

    const [session, setSession] = useContext(SessionContext);

    useEffect(() => {
        const session = sessionStorage.getItem(SESSION_KEY);

        setSession(session);
    }, [setSession]);

    const logIn = useCallback(() => {
        const session = 'order_id';

        sessionStorage.setItem(SESSION_KEY, session);
        setSession(session);
    }, []);

    const logOut = useCallback(() => {
        sessionStorage.removeItem(SESSION_KEY);
        setSession('');
    }, []);

    return {
        session,
        logIn,
        logOut,
    };
}

export default useSessions;