import { useState, useEffect } from "react";
import { fetchTestResultById } from "networking";

import { useParams } from "react-router-dom";

function Result({ data }) {

    const getPdfUrl = () => {
        return `data:application/pdf;base64,${data}`;
    };

    return (
        <iframe className="PDF" src={getPdfUrl()} />
    );
}

function View() {

    const params = useParams();

    const [pdf, setPdf] = useState();

    useEffect(() => {
        async function getApiData() {
            const payload = await fetchTestResultById(params.tenant, params.id);

            setPdf(payload.data)
        }

        getApiData();
    }, []);

    if (pdf == null)
        return <>Loading Test Results...</>;

    return (
        <>
            <Result data={pdf} />
        </>
    );

}

export default View;