import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { useSession } from 'modules/commons/hooks';
import { SessionProvider } from 'modules/commons/contexts';

import Home from 'modules/home';
import View from 'modules/view';

function App() {

  const { session } = useSession();

  return (
    <Router>
      <Switch>
        <Route path="/:tenant/view/:id">
          <View />
        </Route>
        <Route path="/view/:id">
          <View />
        </Route>
        <Route path="/:tenant">
          <Home />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

function SessionApp() {
  return (
    <SessionProvider>
      <App />
    </SessionProvider>
  );
}

export default SessionApp;
