import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiFieldText,
  EuiDatePicker,
  EuiSpacer,
  EuiButton,
  EuiHorizontalRule,
} from "@elastic/eui";

import useForm from "./useForm";

import "./Form.css";

function Form({ isLoading = false, onClick = () => {} }) {
  const { form, onChange, onDobChange, getFormValues } = useForm();

  const _onClick = () => {
    const form = getFormValues();
    onClick(form);
  };

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow fullWidth label="Date of Birth">
            <EuiDatePicker
              fullWidth
              name="dob"
              placeholder="MM/DD/YYYY"
              selected={form.dob}
              disabled={isLoading}
              onChange={onDobChange}
              autoComplete="off"
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule />

      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow fullWidth label="Email Address">
            <EuiFieldText
              fullWidth
              name="email"
              value={form.email}
              disabled={isLoading}
              onChange={onChange}
              autoComplete="off"
              placeholder="Enter Email"
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup className="Form">
        <EuiFlexItem>
          <h1>OR</h1>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow fullWidth label="Phone Number">
            <EuiFieldText
              fullWidth
              name="phone"
              value={form.phone}
              disabled={isLoading}
              onChange={onChange}
              autoComplete="off"
              placeholder="Enter Phone Number"
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiButton fill onClick={_onClick} isLoading={isLoading}>
            <span>Verify</span>
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export default Form;
