import { useCallback, useReducer } from "react";
import _get from "lodash/get";
import { fetchTestResult } from "networking";
import validator from "validator";

const initState = {
  isLoading: false,
  patientReport: {},
  error: null,
};

function validate({ dob, email, phone }) {
  try {
    if (validator.isEmpty(dob)) {
      throw new Error("Invalid dob");
    }

    const isEmailValid = validator.isEmail(email);
    const isPhoneValid = validator.isMobilePhone(phone);

    if (validator.isEmpty(email) && validator.isEmpty(phone)) {
      throw Error("Enter an email or a phone number");
    }

    if (isPhoneValid || isEmailValid) {
      return;
    }

    if (!isEmailValid) {
      throw new Error("Invalid email address");
    } else {
      throw new Error("Invalid phone number");
    }
  } catch (error) {
    return _get(error, "message", "");
  }
}

function useTestResult(tenant) {
  const [state, setState] = useReducer(reducer, initState);

  const loading = () => setState({ type: "loading" });
  const success = (payload) => setState({ type: "success", payload });
  const failure = (error) => setState({ type: "failure", error });

  const fetch = useCallback(async (data) => {
    const error = validate(data);

    if (error) {
      alert(error);
      return;
    }

    try {
      loading();

      const response = await fetchTestResult(tenant, data);
      success(response?.data);
    } catch (error) {
      failure(error);
    }
  }, []);

  return [state, fetch];
}

function reducer(state, action) {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "success":
      return {
        isLoading: false,
        payload: action.payload,
        error: null,
      };
    case "failure":
      return {
        isLoading: false,
        error: action.error,
      };
  }
}

export default useTestResult;
