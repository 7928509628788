import axios from 'utils/axios';

export async function fetchTestResult(tenant, data) {

    const response = await axios({
        url: '/testresults',
        method: 'post',
        headers: {
            'Tenant': tenant ?? "medrite"
        },
        data
    });

    return response;
}


export async function fetchTestResultById(tenant, id) {

    const response = await axios({
        url: `/view/${id}`,
        method: 'get',
        headers: {
            'Tenant': tenant ?? "medrite"
        }
    });

    return response;

}