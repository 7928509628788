import { useState } from "react";
import moment from "moment";

function useForm() {
  const [form, setForm] = useState({
    phone: "",
    email: "",
    dob: null,
  });

  const onChange = (e) => {
    const { name, value } = e.target;

    const _form = {
      ...form,
      [name]: value,
    };

    setForm(_form);
  };

  const onDobChange = (value) => {
    setForm({
      ...form,
      dob: value,
    });
  };

  const getFormValues = () => {
    return {
      ...form,
      dob: moment(form.dob).format("YYYY-MM-DD"),
    };
  };

  return {
    form,
    onChange,
    onDobChange,
    getFormValues,
  };
}

export default useForm;
