import { createContext, useState } from 'react';

const SessionContext = createContext();

function SessionProvider({ children }) {

    const value = useState('');

    return (
        <SessionContext.Provider value={value}>
            {children}
        </SessionContext.Provider>
    );
}

export {
    SessionContext,
    SessionProvider,
};