import {
    EuiPanel,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiCallOut,
} from '@elastic/eui';

import { useLocation, useParams } from "react-router-dom";

import Form from './Form';
import useTestResult from './useTestResult';

import './Home.css';

function Result({ data }) {

    const getPdfUrl = () => {
        return `data:application/pdf;base64,${data}`;
    };

    return (
        <iframe className="PDF" src={getPdfUrl()} />
    );
}

function Home() {

    const searchParams = new URLSearchParams(useLocation().search);
    const id = new URLSearchParams([...searchParams]
        .map(([key, value]) => [key.toLowerCase(), value]))
        .get('id');
        
    const params = useParams();

    const [testResult, fetchTestResult] = useTestResult(params.tenant);

    const onClick = (form) => fetchTestResult({
        id,
        ...form,
    });

    return (
        <>
            {testResult.payload
                ? (
                    <Result data={testResult.payload} />
                )
                : (
                    <EuiPanel className="Home AZ-Center">

                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <img
                                    alt="Logo"
                                    className="Logo"
                                    src={`https://elitelabs.s3.us-east-1.amazonaws.com/public/${params.tenant ?? 'medrite'}/logo.png`}
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>

                        <EuiHorizontalRule />

                        {testResult.error
                            ? (
                                <>
                                    <EuiFlexGroup>
                                        <EuiFlexItem>
                                            <EuiCallOut
                                                title="Sorry, there was an error."
                                                color="danger"
                                                iconType="alert"
                                            />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                    <EuiHorizontalRule />
                                </>
                            )
                            : null
                        }

                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <Form
                                    isLoading={testResult.isLoading}
                                    onClick={onClick}
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>

                    </EuiPanel>
                )
            }
        </>
    );

}

export default Home;